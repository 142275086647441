<template>
  <div
    class="col-md-10 mx-auto position-relative loader"
    :class="$route.path == '/unidade' ? 'h-100px d-flex align-items-center' : 'h-100 d-flex align-items-center'"
  >
    <div
      class="spinner"
      :style="`border: calc(${size}px / 8) solid #f6f6f6;width: ${size}px;height: ${size}px;border-bottom-color:${color}!important;`"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["color", "size"]
};
</script>

<style scoped>
.h-100px {
  height: 100px;
}
.loader {
  width: 100%;
  /* height: 240px; */
}
.spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  border-radius: 50%;

  border-bottom-color: #2474b2;
  animation: rotateSpinner 500ms linear infinite;
}

@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}
</style>